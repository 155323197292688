import { translate } from "./translate";

export function getBase64(base64String = '', pureBase64 = false, placeholderImg = 'img/logo-icon-jobifull.png'){
    if(base64String == '' || base64String == null){
        if(pureBase64)
            return `${process.env.VUE_APP_BASE_URL}${placeholderImg}`;
        return `url(${process.env.VUE_APP_BASE_URL}${placeholderImg})`;
    }
    if(pureBase64)
        return `data:image/png;base64,${base64String}`;
    return `url(data:image/png;base64,${base64String})`;
}

export async function getOnPublicImagesUrl(fileName = '', defaultImage = 'img/logo-icon-jobifull.png'){
    let requestedUrl = `${process.env.VUE_APP_BASE_URL}${fileName}`;
    const result = await resourceExists(requestedUrl);
    if(result){
        return requestedUrl;
    }else{
        return `${process.env.VUE_APP_BASE_URL}${defaultImage}`;
    }
}

export function getOnPublic(fileName = ''){
    return `${process.env.VUE_APP_BASE_URL}${fileName}`;
} 

export async function resourceExists(url) {
    try {
      const response = await fetch(url, { method: 'HEAD' });
    //   if(!response.ok)
    //     console.clear();
      return response.ok;
    } catch (error) {
      return false;
    }
}

export function isImageValid(file){
    let response = {
        status: false,
        message: 'error'
    }
    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedMimeTypes.includes(file.type)) {
        response.message = translate('accepted formats are: JPEG, PNG and GIF', 'ucfirst');
        return response;
    }
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    if (file.size > maxSizeInBytes) {
        response.message = translate('max allowed size is 2 MB', 'ucfirst');
        return response;
    }
    response.status = true;
    return response;
}