import requestHandler from '@/composables/requestHandler';
import Translation from '@/models/Translation';
import store from '@/store';
import countries from './country';
import companyType from './companyType';
import utils from './utils';
import { ucfirst } from './textUtils';
import commonCurrencies from './commonCurrency';
import languageData from './languageData';

const companyData = {
    async listCompanyTypes() {
        try {
            const response = await requestHandler.executeRequest('api/company_types/getcompanytypes', {}, 'get');
            let companyTypes = [];
            let companyTypesArray = [];
            for (let languageData of response.data) {
                let companyTypesObj = new Translation(languageData);
                companyTypesObj.setTargetLanguageIso(store.getters.getUserLang);
                companyTypes.push(companyTypesObj);
                companyTypesArray.push({ name: companyTypesObj.targetLanguageIsoCode, id: companyTypesObj.company_type_id });
            }
            return { objects: companyTypes, names: companyTypesArray };
        } catch (error) {
            return [`Error: ${error}`]
        }
    },

    async listCompanies(limit = 10, page = 1){
        let params = {
            per_page: limit,
            page: page,
            user_lang: store.getters.getUserLang
        };
        return requestHandler.executeRequest('api/company/index', params, 'get');
    },

    async getComboData(only = null) {
        let filtersAvaliable = {
            company_type: [],
            ddi: [],
            currency: [],
            language: []
        };
        let langIso = store.getters.getUserLang;
        if (!['en', 'pt', 'es'].includes(langIso))
            langIso = 'en';
        for (let name of Object.keys(filtersAvaliable)) {
            if (only !== null)
                name = only;
            let groupedData = [];
            switch (name) {
                case 'company_type':
                    filtersAvaliable[name] = utils.parseToArray(await companyType.listCompanyTypes());
                    for (let object of filtersAvaliable[name]) {
                        groupedData.push({ name: ucfirst(object[langIso]), code: object['company_type_id'] })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'ddi':
                    filtersAvaliable[name] = utils.parseToArray(await countries.listCountries());
                    for (let object of filtersAvaliable[name]) {
                        let id = ![null, undefined, ''].includes(object['lcountry_id']) ? object['lcountry_id'] : object['object_id'];
                        groupedData.push({ 
                            name: ucfirst(object[langIso]), code: id, ddi: object['ddi'], flag: object['flag'], flagSrc: `data/flags/${object['lcountry_acronyn']}.svg`
                        })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'currency':
                    filtersAvaliable[name] = utils.parseToArray(await commonCurrencies.listCommonCurrencies());
                    for (let object of filtersAvaliable[name]) {
                        let id = ![null, undefined, ''].includes(object['common_currency_id']) ? object['common_currency_id'] : object['object_id'];
                        groupedData.push({ name: ucfirst(object[langIso]), code: id })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                case 'language':
                    filtersAvaliable[name] = utils.parseToArray(await languageData.getAllLanguages());
                    for (let object of filtersAvaliable[name]) {
                        let id = ![null, undefined, ''].includes(object['llangue_id']) ? object['llangue_id'] : object['object_id'];
                        groupedData.push({ name: ucfirst(object[langIso]), code: id })
                    }
                    filtersAvaliable[name] = groupedData;
                    break;
                default:
                    return [];
            }
            if (only !== null)
                break;
        }
        return only ? filtersAvaliable[only] : filtersAvaliable;
    },
}

export default companyData;