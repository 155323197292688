import requestHandler from '@/composables/requestHandler';
import store from '@/store';
import utils from './utils';

const companyType = {
    async listCompanyTypes() {
        try {
            let companyTypeCombo = store.getters.getOnComboChest('company_type');
            if(companyTypeCombo !== null && companyTypeCombo !== undefined && !utils.isEmpty(companyTypeCombo))
                return companyTypeCombo;
            const result = await requestHandler.executeRequest('api/company_types/getcompanytypes', {}, 'get');
            store.dispatch('saveOnComboChest', {
                key: 'company_type',
                value: result.data
            });
            return result.data;
        } catch (error) {
            return [`Error: ${error}`]
        }
    }
}

export default companyType;